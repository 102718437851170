<template>
  <div class="breadcrumbs">
    <div class="breadcrumbs__container">
      <router-link
        :to="{name: correctingBreadcrumbs, params: {pathMatch: ''}}"
        class="breadcrumbs__link"
      >
        {{ pageName }}
      </router-link>
      <span class="breadcrumbs__split">/</span>
    </div>
    <template v-if="breadcrumbs.length">
      <div
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="`breadcrumb-${index}`"
        class="breadcrumbs__container"
      >
        <router-link
          :to="`${mainPath}/${breadcrumb.url}`"
          class="breadcrumbs__link"
        >
          {{ breadcrumb.name }}
        </router-link>
        <span class="breadcrumbs__split">/</span>
      </div>
    </template>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const {mapState} = createNamespacedHelpers('breadcrumbs');

export default {
  name: 'ElkBreadcrumbs',
  props: ['pageName'],
  computed: {
    ...mapState(['breadcrumbs']),
    correctingBreadcrumbs() {
      let routerName = this.$route.name;
      // for StudentService fix
      if (routerName === 'elk.student_service') {
        routerName = 'elk.student_services';
      }
      return routerName;
    },
    mainPath() {
      return this.$route.matched[0].path.split('*')[0];
    }
  }
};
</script>
